<template>
    <footer>
      <p class="web-credit">
        Website by
        <a
          class="footer-link"
          href="https://davyjones.me"
          target="_blank"
          >Davy Jones</a
        >
      </p>
      <div class="footer-bottom">
        <p class="copyright">
          © 2022<span class="line-break">|</span>
          Images from
          <a href="https://artvee.com/" class="footer-link">Artvee.com</a>
        </p>
      </div>
    </footer>
</template>
<script>
export default {
    name: 'Footer'
}

</script>